@charset "UTF-8";
@import url("https://fonts.googleapis.com/css?family=Merriweather:400,400i,700,700i&display=swap");
@import url("https://fonts.googleapis.com/css?family=Roboto:300,400,700&display=swap");
/* ==========================================================================
   Base styles: opinionated defaults
   ========================================================================== */
/* Main Primary color Foreign crimson #C91F37 */
/* ==========================================================================
   Helper classes
  ========================================================================== */
/* ==========================================================================
   Global custom styles
   ========================================================================== */
html {
  font-family: "Roboto", sans-serif;
  color: #424242;
}

a:hover {
  color: #c91f37;
}

::-moz-selection {
  background: #E81123;
  color: white;
}

::selection {
  background: #E81123;
  color: white;
}

.uk-section {
  padding-top: 1rem;
  padding-bottom: 1rem;
}

.ep_img_cover {
  border-radius: 2px;
  -o-object-fit: cover;
     object-fit: cover;
  width: 100%;
}

@media (max-width: 640px) {
  .ep_img_cover {
    height: 200px;
  }
}

.ep_button_primary {
  background-color: #E81123;
  font-family: "Roboto", sans-serif;
  font-size: 0.75rem;
  font-weight: 700;
  color: white;
  text-decoration: none;
  padding: 0.25rem 0.5rem;
  border-radius: 2px;
  -webkit-box-shadow: 0 2px 0 0 #bc172a;
          box-shadow: 0 2px 0 0 #bc172a;
}

.ep_button_primary:hover {
  color: white;
  background-color: #c91f37;
  text-decoration: none;
}

.ep_button_secondary {
  background-color: #f8f8f8;
  font-family: "Roboto", sans-serif;
  font-size: 0.75rem;
  font-weight: 700;
  color: black;
  text-decoration: none;
  padding: 0.25rem 0.5rem;
  border-radius: 2px;
  -webkit-box-shadow: 0 2px 0 0 #e5e5e5;
          box-shadow: 0 2px 0 0 #e5e5e5;
}

.ep_button_secondary:hover {
  color: black;
  background-color: #F5F5F5;
  text-decoration: none;
}

.ep_button_light {
  background-color: white;
  font-family: "Roboto", sans-serif;
  font-size: 1rem;
  font-weight: 700;
  color: black;
  text-decoration: none;
  padding: 0.5rem 1rem;
  border-radius: 2px;
  -webkit-box-shadow: 0 2px 0 0 #e5e5e5;
          box-shadow: 0 2px 0 0 #e5e5e5;
  border: 1px solid #e5e5e5;
}

.ep_button_light:hover {
  color: black;
  background-color: #fcfcfc;
  text-decoration: none;
}

.ep_button_dark {
  background-color: #FFB900;
  font-family: "Roboto", sans-serif;
  font-size: 1rem;
  font-weight: 700;
  color: black;
  text-decoration: none;
  padding: 0.5rem 1rem;
  border-radius: 2px;
  -webkit-box-shadow: 0 2px 0 0 #cc9400;
          box-shadow: 0 2px 0 0 #cc9400;
  border: 1px solid #cc9400;
}

.ep_button_dark:hover {
  color: black;
  background-color: #ffc733;
  text-decoration: none;
}

/* ==========================================================================
   Utils classes
  ========================================================================== */
.ep-bottom-divider {
  margin: 15px 0 30px 0;
}

.ep-bottom-divider::after {
  content: '';
  position: relative;
  display: block;
  border-bottom: 1px solid #f8f8f8;
  height: 2px;
}

.ep-section-header {
  border-top: 1px solid #f8f8f8;
  border-bottom: 1px solid #e5e5e5;
  margin-bottom: 1rem !important;
}

@media (max-width: 640px) {
  .ep-section-header {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
  }
}

.ep-section-header .ep-section-title {
  font-family: "Roboto", sans-serif;
  font-weight: 700;
  color: #222;
  text-decoration: none;
  font-size: 1rem;
  text-transform: uppercase;
  font-family: "Roboto", sans-serif;
  background-color: #f8f8f8;
  padding: 0.25rem 0.5rem;
  max-height: 1.5rem;
}

.ep-section-header .ep-section-title:hover {
  color: black;
}

@media (max-width: 640px) {
  .ep-section-header .ep-section-title {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    width: calc(100% - 1rem);
  }
}

.ep-section-header .ep-section-subnav {
  font-size: 0.75rem;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.ep-section-header .ep-section-subnav .ep-section-subtitle {
  margin-right: 0.5rem;
}

.ep-section-header .ep-section-subnav .uk-subnav {
  margin-bottom: 0;
}

.ep-section-header .ep-section-subnav .uk-subnav li {
  padding-left: 10px;
  font-size: 0.75rem;
}

.ep-section-header .ep-section-subnav .uk-subnav li a {
  font-size: 0.75rem;
  text-transform: none;
  font-family: "Roboto", sans-serif;
  font-weight: 700;
  color: #c91f37;
  text-decoration: none;
}

.ep-section-header .ep-section-subnav .uk-subnav li a:hover {
  color: #eb7d8c;
}

.ep-section-header .ep-section-subnav .uk-subnav li:first-child {
  padding-left: 0;
}

.ep-section-header .ep-section-subnav .uk-subnav li::before {
  height: 0.5rem;
  margin-right: 10px;
  border-left-color: #e5e5e5;
}

#ep_post_style {
  font-family: "Merriweather", serif;
}

#ep_post_style .ep_post_title {
  font-size: 2.5rem;
  font-family: "Merriweather", serif;
}

#ep_post_style .ep_post_subtitle {
  font-size: 1.25rem;
  font-family: "Merriweather", serif;
  font-style: italic;
}

@media (max-width: 640px) {
  #ep_post_style .uk-clearfix {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
  }
}

.ep_post_meta .uk-subnav {
  font-family: "Roboto", sans-serif;
  margin-left: 0;
}

.ep_post_meta .uk-subnav li {
  padding-left: 10px;
  font-size: 0.75rem;
}

.ep_post_meta .uk-subnav li a {
  font-size: 0.75rem;
  text-transform: none;
  font-family: "Roboto", sans-serif;
  font-weight: 700;
  color: #999;
  text-decoration: none;
}

.ep_post_meta .uk-subnav li a:hover {
  color: #d9d9d9;
}

.ep_post_meta .uk-subnav li:first-child {
  padding-left: 0;
}

.ep_post_meta .uk-subnav li::before {
  height: 0.5rem;
  margin-right: 10px;
  border-left-color: #e5e5e5;
}

.ep_post_meta .uk-subnav li:nth-child(n+2):not(.uk-first-column)::before {
  height: 0.5rem;
  margin-right: 10px;
}

.ep_post_meta .uk-subnav li a[uk-icon*="icon"] {
  font-family: "Roboto", sans-serif;
  font-weight: 700;
  color: #c91f37;
  text-decoration: none;
}

.ep_post_meta .uk-subnav li a[uk-icon*="icon"]:hover {
  color: #eb7d8c;
}

.ep_post_related .ep_post_related_title {
  font-size: 1rem;
  font-family: "Merriweather", serif;
  font-weight: bold;
  color: #333 !important;
}

.ep_post_related .ep_post_related_title:hover {
  color: #c91f37 !important;
  text-decoration: none;
}

.ep_post_tags .uk-subnav li:not(:first-child) {
  padding-left: 10px;
}

.ep_post_tags .uk-subnav li span {
  color: #c91f37;
}

.ep_post_tags .uk-subnav li a {
  color: #c91f37;
  font-size: 0.75rem;
  font-family: "Roboto", sans-serif;
  text-transform: none;
  font-weight: 700;
  padding: 0 0.75rem;
  border: 1px solid #e5e5e5;
}

.ep_social_share a {
  color: white;
}

.ep_social_share a[uk-icon*="twitter"] {
  background-color: #55acee;
}

.ep_social_share a[uk-icon*="facebook"] {
  background-color: #3b5999;
}

.ep_social_share a[uk-icon*="whatsapp"] {
  background-color: #25D366;
}

.ep_social_share a[uk-icon*="linkedin"] {
  background-color: #0077B5;
}

.ep_social_share a[uk-icon*="mail"] {
  background-color: #f57d00;
}

.ep_social_share a:hover {
  color: #F5F5F5;
}

.ep_social_share_alt a:not(:first-child) {
  margin-left: 5px;
}

.ep_social_share_alt a span {
  padding-right: 5px;
}

.ep_social_share_iconnav li:first-child {
  padding-left: 0;
}

.ep_post_popular ul li a {
  font-size: 1rem;
  font-family: "Merriweather", serif;
  font-weight: bold;
  color: #333 !important;
}

.ep_post_popular ul li a:hover {
  color: #c91f37 !important;
  text-decoration: none;
}

.ep_post_popular ul li a .ep-number-list {
  display: inline-block;
  font-size: 2.5rem;
  text-align: right;
  font-style: italic;
  color: black;
  font-weight: 400;
  font-weight: 700;
  min-width: 2.8rem;
}

@media (max-width: 640px) {
  #ep_list_posts .uk-card-body {
    padding: 0;
    margin-top: 1rem;
  }
}

#ep_list_posts .uk-card-body .uk-card-title a {
  font-size: 1.25rem;
  font-family: "Merriweather", serif;
  font-weight: bold;
  color: #333 !important;
}

#ep_list_posts .uk-card-body .uk-card-title a:hover {
  color: #c91f37 !important;
  text-decoration: none;
}

@media (max-width: 640px) {
  #ep_list_posts .uk-card-body .ep_post_meta {
    display: none;
  }
}

#ep_list_posts .uk-card-body .ep_post_meta .uk-subnav {
  padding-left: 0;
}

#ep_list_opinion .ep-post-title {
  font-size: 1.25rem;
  font-family: "Merriweather", serif;
  font-weight: bold;
  color: #333 !important;
}

#ep_list_opinion .ep-post-title:hover {
  color: #c91f37 !important;
  text-decoration: none;
}

@media (max-width: 640px) {
  #ep_list_opinion .ep_post_meta {
    display: none;
  }
}

#ep_list_opinion .ep_post_meta .uk-subnav {
  padding-left: 0;
}

.ep_initial_letter span {
  width: 5rem;
  height: 5rem;
  background-color: #333;
  color: #f8f8f8;
  font-size: 1.75rem;
  font-weight: 700;
}

.ep_header_section .ep-section-line {
  margin-bottom: 15px;
}

.ep_header_section_list span {
  font-size: 2.5rem;
  font-family: "Roboto", sans-serif;
  font-weight: 700;
  color: #222;
  text-decoration: none;
}

.ep_block_title {
  font-size: 1rem;
  font-weight: 700;
  color: #222;
  text-transform: uppercase;
}

.ep_block_title::before {
  content: '';
  position: relative;
  display: block;
  border-top: 5px solid black;
  padding-top: 0.25rem;
  width: 3rem;
}

#ep_comments {
  font-family: "Merriweather", serif;
}

#ep_comments .uk-comment-list .uk-comment ~ ul > :nth-child(n+2) {
  margin-top: 2rem;
}

#ep_comments .uk-comment-list .uk-comment ~ ul {
  margin-top: 2rem;
}

#ep_comments .uk-comment-list > :nth-child(n+2) {
  margin-top: 2rem;
}

.ep_author_card .uk-card-header .uk-card-title a {
  font-family: "Merriweather", serif;
  font-weight: bold;
  color: #333 !important;
}

.ep_author_card .uk-card-header .uk-card-title a:hover {
  color: #c91f37 !important;
  text-decoration: none;
}

.ep_tag_card .uk-card a {
  font-family: "Merriweather", serif;
  font-weight: bold;
  color: #333 !important;
}

.ep_tag_card .uk-card a:hover {
  color: #c91f37 !important;
  text-decoration: none;
}

.ep_tag_card .uk-card a .uk-badge {
  font-family: "Roboto", sans-serif;
  background-color: #E81123;
}

#ep_author_profile .ep-author-title {
  font-family: "Merriweather", serif;
  font-weight: bold;
  color: #333;
}

@media (min-width: 640px) {
  #ep_multimedia_posts dl {
    margin-left: 10px;
  }
}

@media (max-width: 640px) {
  #ep_multimedia_posts dl {
    margin-bottom: 1rem;
  }
}

#ep_multimedia_posts dl dt {
  font-weight: bold;
}

#ep_multimedia_posts dl dt:nth-child(n+2) {
  margin-top: 10px;
  padding-top: 10px;
}

#ep_multimedia_posts dl dd {
  font-family: "Merriweather", serif;
}

.ep_multimedia_item_list .uk-card-secondary {
  background-color: #333;
}

.ep_multimedia_item_list .uk-card-secondary .uk-card-header {
  border-bottom: 1px solid #222;
}

.ep_multimedia_item_list .uk-card-secondary .uk-card-header .uk-card-title {
  font-family: "Merriweather", serif;
  font-weight: bold;
  color: #FFB900 !important;
}

.ep_multimedia_item_list .uk-card-secondary .uk-card-header .uk-card-title:hover {
  color: rgba(255, 185, 0, 0.75) !important;
  text-decoration: none;
}

@media (max-width: 640px) {
  .ep_multimedia_item_list .uk-card-secondary .uk-card-header .uk-card-title {
    font-size: 1rem;
  }
}

@media (min-width: 640px) and (max-width: 960px) {
  .ep_multimedia_item_list .uk-card-secondary .uk-card-header .uk-card-title {
    font-size: 1.25rem;
  }
}

@media (max-width: 960px) {
  .ep_multimedia_item_list .uk-card-secondary .uk-card-body img {
    height: 200px;
  }
}

.ep_multimedia_item_list .uk-card-secondary .uk-card-footer {
  border-top: 1px solid #222;
}

#ep_multimedia_dark_list {
  font-family: "Merriweather", serif;
}

#ep_multimedia_dark_list .uk-card-secondary {
  background-color: #333;
}

#ep_multimedia_dark_list .uk-card-secondary .uk-list li:first-child {
  color: #FFB900;
}

#ep_multimedia_dark_list .uk-card-secondary .uk-list li:nth-child(2) {
  font-family: "Roboto", sans-serif;
}

#ep_multimedia_dark_list .uk-card-secondary .uk-list li:nth-child(n+2) {
  border-top-color: #222;
}

#ep_multimedia_dark_list .uk-card-secondary .ep_social_share_iconnav a {
  font-family: "Merriweather", serif;
  font-weight: bold;
  color: #FFB900 !important;
}

#ep_multimedia_dark_list .uk-card-secondary .ep_social_share_iconnav a:hover {
  color: rgba(255, 185, 0, 0.75) !important;
  text-decoration: none;
}

/* ==========================================================================
   Header styles
   ========================================================================== */
#ep-topbar {
  border-bottom: 1px solid #e5e5e5;
  padding-bottom: 0.5rem;
}

#ep-topbar > div[class*="uk-navbar-"] {
  -webkit-box-align: end;
      -ms-flex-align: end;
          align-items: end;
}

#ep-topbar > div[class*="uk-navbar-"] .uk-navbar-item {
  min-height: 1.5rem;
  position: relative;
  top: 8px;
}

#ep-topbar > div[class*="uk-navbar-"] .uk-navbar-item a:not(:first-child) {
  padding-left: 10px;
}

#ep-topbar > div[class*="uk-navbar-"] .ep_post_meta .uk-subnav {
  margin-bottom: 0;
}

#ep-mainbar {
  background-color: white;
  border-top: 1px solid #333;
  border-bottom: 3px solid #333;
}

#ep-mainbar:before {
  content: '';
  position: absolute;
  border-top: 1px solid #e5e5e5;
  border-bottom: 1px solid #999;
  top: -7px;
  width: 100%;
  height: 2px;
  left: 0px;
}

/* ==========================================================================
   Footer styles
   ========================================================================== */
#ep-footer-section {
  background-color: black;
  margin-top: 2rem;
}

@media (max-width: 640px) {
  #ep-footer-section #ep-footer-body #ep-block-level-1 {
    text-align: center;
  }
}

#ep-footer-section #ep-footer-body #ep-block-level-1 > div:not(.uk-first-column)::before {
  border-left-color: rgba(255, 255, 255, 0.1);
}

@media (max-width: 640px) {
  #ep-footer-section #ep-footer-body #ep-block-level-1 > div::before {
    border-top-color: rgba(255, 255, 255, 0.25);
  }
}

#ep-footer-section #ep-footer-body #ep-block-level-1 .ep-logo-svg a svg {
  max-width: 200px;
}

#ep-footer-section #ep-footer-body #ep-block-level-1 .ep-logo-svg a svg .ep-logo-black {
  fill: white;
}

#ep-footer-section #ep-footer-body #ep-block-level-1 .uk-list li:nth-child(n+2) {
  border-top-color: rgba(255, 255, 255, 0.1);
}

#ep-footer-section #ep-footer-body #ep-block-level-1 .uk-list li a {
  font-family: "Roboto", sans-serif;
  font-weight: 700;
  color: #f8f8f8;
  text-decoration: none;
}

#ep-footer-section #ep-footer-body #ep-block-level-1 .uk-list li a:hover {
  color: white;
}

#ep-footer-section #ep-footer-body #ep-block-level-1 .ep-footer-column-title {
  font-size: 0.875rem;
  text-transform: uppercase;
  font-weight: bold;
}

#ep-footer-section #ep-footer-body #ep-block-level-2::before {
  border-top-color: rgba(255, 255, 255, 0.1);
}

#ep-footer-section #ep-footer-body #ep-block-level-2 .ep-footer-copyright {
  font-size: 0.75rem;
  text-align: center;
}

#ep-footer-section #ep-footer-body #ep-block-level-2 .ep-footer-copyright a {
  font-family: "Roboto", sans-serif;
  font-family: "Roboto", sans-serif;
  font-weight: 700;
  color: #FFB900;
  text-decoration: none;
}

#ep-footer-section #ep-footer-body #ep-block-level-2 .ep-footer-copyright a:hover {
  color: #ffdc80;
}

/* ==========================================================================
Estilos seccion popular
========================================================================== */
#ep-popular .ep_header_popular {
  border-bottom: 1px solid #e5e5e5;
  padding-bottom: 0.25rem;
}

#ep-popular .ep-block-level-1 dl dt {
  font-size: 0.75rem;
}

#ep-popular .ep-block-level-1 dl dd a {
  font-size: 1.25rem;
  font-family: "Merriweather", serif;
  font-weight: bold;
  color: #333 !important;
}

#ep-popular .ep-block-level-1 dl dd a:hover {
  color: #c91f37 !important;
  text-decoration: none;
}

@media (max-width: 960px) {
  #ep-popular .ep-block-level-1 dl dd a {
    font-size: 1rem;
  }
}

/* ==========================================================================
Estilos seccion Opinion
========================================================================== */
#ep-opinion-secction #editorial {
  background-color: #FAF1F2;
  border-top: 3px solid #F4BBC3;
  overflow: hidden;
}

#ep-opinion-secction #editorial::after {
  content: "";
  background: linear-gradient(-45deg, white 16px, transparent 0), linear-gradient(45deg, white 16px, transparent 0);
  background-size: 12px 32px;
  position: absolute;
  bottom: 0px;
  left: 0px;
  width: 100%;
  height: 32px;
}

#ep-opinion-secction #editorial p {
  z-index: 0;
  position: relative;
}

#ep-opinion-secction #editorial p::before {
  content: "”";
  color: #F3E1E4;
  font-family: Georgia,serif;
  font-size: 26rem;
  position: absolute;
  right: -3rem;
  bottom: -26rem;
  z-index: -1;
}

#ep-opinion-secction #editorial .uk-heading-bullet {
  text-transform: uppercase;
  font-weight: 700;
  font-family: "Roboto", sans-serif;
  font-size: 0.75rem;
}

#ep-opinion-secction #editorial .uk-heading-bullet::before {
  border-left-color: #DEC8CB;
}

#ep-opinion-secction #editorial .uk-heading-bullet span::before, #ep-opinion-secction #editorial .uk-heading-bullet span::after {
  border-bottom-color: #DEC8CB;
}

#ep-opinion-secction #editorial .uk-card-title a {
  font-family: "Merriweather", serif;
  font-weight: bold;
  color: #333 !important;
}

#ep-opinion-secction #editorial .uk-card-title a:hover {
  color: #c91f37 !important;
  text-decoration: none;
}

@media (max-width: 960px) {
  #ep-opinion-secction #editorial .uk-card-title a {
    font-size: 1.25rem;
  }
}

#ep-opinion-secction #editorial p {
  font-family: "Merriweather", serif;
}

#ep-opinion-secction #reflexion .uk-heading-line {
  text-transform: uppercase;
  font-weight: 700;
  font-family: "Roboto", sans-serif;
  font-size: 0.75rem;
}

#ep-opinion-secction #reflexion .uk-heading-line::before {
  content: " ";
  position: absolute;
  width: 6rem;
  height: 1.5rem;
  background: #F4BBC3;
  left: calc(50% - 3rem);
  margin-top: -0.25rem;
  z-index: -1;
}

#ep-opinion-secction #reflexion .uk-heading-line::after {
  content: " ";
  position: absolute;
  left: calc(50% - 3rem);
  border-left: 3rem solid #F4BBC3;
  border-right: 3rem solid #F4BBC3;
  border-bottom: 0.25rem solid white;
  margin-top: 1.25rem;
}

#ep-opinion-secction #reflexion .uk-heading-line span::before, #ep-opinion-secction #reflexion .uk-heading-line span::after {
  border-bottom-color: #222;
  border-bottom-width: 3px;
  z-index: -2;
}

#ep-opinion-secction #reflexion .uk-card-title a {
  font-family: "Merriweather", serif;
  font-weight: bold;
  color: #333 !important;
}

#ep-opinion-secction #reflexion .uk-card-title a:hover {
  color: #c91f37 !important;
  text-decoration: none;
}

@media (max-width: 960px) {
  #ep-opinion-secction #reflexion .uk-card-title a {
    font-size: 1.25rem;
  }
}

#ep-opinion-secction #reflexion p {
  font-family: "Merriweather", serif;
}

#ep-opinion-secction #tema-del-dia .uk-card .uk-card-media-top img {
  height: 300px;
}

#ep-opinion-secction #tema-del-dia .uk-card .uk-card-media-top::after {
  content: attr(ep-attr-title);
  text-transform: uppercase;
  font-weight: 700;
  font-family: "Roboto", sans-serif;
  font-size: 0.75rem;
  text-align: center;
  background-color: #52272D;
  color: white;
  position: absolute;
  display: block;
  left: calc(50% - 3.5rem);
  width: 7rem;
  height: 1.5rem;
  opacity: 0.9;
  z-index: 1;
  margin-top: -1rem;
  padding-top: 0.25rem;
}

#ep-opinion-secction #tema-del-dia .uk-card .uk-card-body div {
  font-family: "Merriweather", serif;
}

#ep-opinion-secction #opinion .uk-card:last-child::before {
  content: "";
  position: relative;
  display: block;
  margin: 0 0 0.75rem 0;
  border-top: 1px solid #e5e5e5;
}

@media (min-width: 640px) and (max-width: 960px) {
  #ep-opinion-secction #opinion .uk-card:first-child::before {
    content: "";
    position: relative;
    display: block;
    margin: 0 0 0.75rem 0;
    border-top: 1px solid #e5e5e5;
  }
}

#ep-opinion-secction #opinion .uk-card .uk-card-header .uk-card-title a {
  font-family: "Merriweather", serif;
  font-weight: bold;
  color: #333 !important;
}

#ep-opinion-secction #opinion .uk-card .uk-card-header .uk-card-title a:hover {
  color: #c91f37 !important;
  text-decoration: none;
}

@media (max-width: 960px) {
  #ep-opinion-secction #opinion .uk-card .uk-card-header .uk-card-title a {
    font-size: 1.25rem;
  }
}

#ep-opinion-secction #opinion .uk-card .uk-card-body {
  padding-top: 15px;
  font-family: "Merriweather", serif;
}

/* ==========================================================================
Estilos seccion videos
========================================================================== */
#ep-videos-section {
  background-color: #222;
}

#ep-videos-section.ep_section_tags {
  border-top: 3px solid #c91f37;
  padding-top: 0.25rem;
  margin-top: 1rem;
  margin-bottom: 1rem;
}

#ep-videos-section .ep-section-line {
  border-top-color: rgba(245, 245, 245, 0.1);
}

@media (min-width: 640px) {
  #ep-videos-section .ep-video-block::before, #ep-videos-section .ep-video-block::after {
    content: '';
    background-color: rgba(0, 0, 0, 0.5);
    position: absolute;
    height: 100%;
    width: 25%;
    top: 0;
    z-index: 10;
  }
  #ep-videos-section .ep-video-block::before {
    left: 0%;
  }
  #ep-videos-section .ep-video-block::after {
    right: 0%;
  }
}

#ep-videos-section .ep-video-block .ep-video-list .ep-video-item {
  font-family: "Merriweather", serif;
  font-size: 1rem;
}

#ep-videos-section .ep-video-block .ep-video-list .ep-video-item a img {
  height: 400px;
}

@media (max-width: 960px) {
  #ep-videos-section .ep-video-block .ep-video-list .ep-video-item a img {
    height: 300px;
  }
}

#ep-videos-section .ep-video-block .ep-video-list .ep-video-item a .uk-overlay-default {
  padding: 0;
  background-color: #c91f37;
  color: white;
  border-radius: 50%;
  opacity: 0.9;
}

#ep-videos-section .ep-video-block .ep-video-list .ep-video-item a .uk-overlay-default:hover {
  opacity: 0.75;
}

#ep-videos-section .ep-video-block .ep-video-list .ep-video-item a .uk-overlay-primary {
  padding: 0.5rem;
  border-top: 1px solid #FFB900;
  background-color: rgba(0, 0, 0, 0.85);
}

#ep-videos-section .ep-video-block .ep-video-list .ep-video-item a .uk-overlay-primary .ep-video-title {
  color: #FFB900;
  font-weight: 700;
}

#ep-videos-section .ep-video-block .ep-video-list .ep-video-item a .uk-overlay-primary .ep-video-meta {
  font-size: 0.75rem;
  color: white;
}

#ep-videos-section .ep-video-block .uk-slidenav {
  color: rgba(255, 255, 255, 0.75);
  z-index: 12;
}

/* ==========================================================================
Portadas styles
========================================================================== */
#ep-block-portadas .uk-slidenav {
  color: rgba(255, 255, 255, 0.75);
}

/* ==========================================================================
   Footer styles
   ========================================================================== */
#adoptable .ep_adaptable_title {
  font-family: "Merriweather", serif;
  font-size: 1.25rem;
  font-weight: 700;
  color: #FFB900;
}

@media (min-width: 640px) and (max-width: 960px) {
  #adoptable [uk-slideshow] {
    height: 400px;
  }
}

@media (min-width: 640px) and (max-width: 960px) {
  #adoptable [uk-slideshow] ul li {
    height: 400px;
  }
}

#adoptable [uk-slideshow] ul li img {
  height: 400px;
}

#adoptable .ep_adaptable_cat img {
  position: absolute;
  z-index: 10;
  top: -19px;
  right: 0;
}

#adoptable .ep_adaptable_cat::before {
  content: url("../img/mascotas/ovillo.png") !important;
  z-index: 99;
  position: absolute;
  top: -10px;
  right: 51px;
}

#adoptable .ep_adaptable_dog img {
  position: absolute;
  z-index: 10;
  bottom: -30px;
}

#adoptable .ep_adaptable_dog::after {
  content: url("../img/mascotas/hueso.png") !important;
  z-index: 99;
  position: absolute;
  bottom: -15px;
  left: 52px;
}

#ep-table-position .uk-table tbody tr td {
  font-size: 0.75rem;
}

/* ==========================================================================
   Sticky Ads style
  ========================================================================== */
#sticky_ads {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  bottom: 0;
  position: fixed;
  z-index: 999;
}

@media (min-width: 640px) {
  #sticky_ads {
    display: none;
  }
}

#sticky_ads .ep_button_ads {
  margin: 0 1rem;
}

/* ==========================================================================
   Navbar center desktop styles
   ========================================================================== */
#ep-navbar-center-desktop .uk-navbar-nav > li:before {
  margin-right: 0;
}

#ep-navbar-center-desktop .uk-navbar-nav > li [class*="uk-dropdown-bottom"] {
  margin-top: 3px;
}

#ep-navbar-center-desktop .uk-navbar-nav > li [class*="uk-dropdown-top"] {
  margin-bottom: 3px;
}

#ep-navbar-center-desktop .uk-navbar-nav > li > a {
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  min-height: 3rem;
  font-family: "Roboto", sans-serif;
  font-weight: 700;
  color: #333;
}

#ep-navbar-center-desktop .uk-navbar-nav > li > a:hover {
  -webkit-box-shadow: 0 5px 0 -1px #c91f37;
          box-shadow: 0 5px 0 -1px #c91f37;
}

/* ==========================================================================
   Navbar center desktop styles
   ========================================================================== */
#ep-navbar-left-desktop .uk-navbar-nav .uk-navbar-toggle {
  min-height: 3rem;
  font-weight: 700;
  color: #333;
}

#ep-navbar-left-desktop .uk-navbar-nav [class*="uk-drop-bottom"] {
  margin-top: 0;
}

#ep-navbar-left-desktop .uk-navbar-nav [class*="uk-drop-top"] {
  margin-top: 0;
}

#ep-navbar-left-desktop .uk-navbar-nav .uk-navbar-dropdown-nav .uk-nav-header {
  font-family: "Roboto", sans-serif;
  font-weight: 300;
}

/* ==========================================================================
   Navbar right search styles
   ========================================================================== */
#ep-navbar-right-search [class*="uk-navbar-dropdown-bottom"] {
  margin-top: 3px;
}

#ep-navbar-right-search [class*="uk-navbar-dropdown-top"] {
  margin-bottom: 3px;
}

#ep-navbar-right-search > a {
  min-height: 3rem;
}

#ep-navbar-right-search .uk-navbar-dropdown {
  padding: 0.5rem 1rem;
  width: 300px;
}

@media (max-width: 640px) {
  #ep-navbar-right-search .uk-navbar-dropdown {
    width: 100%;
  }
}

/* ==========================================================================
   Offcanvas nav mobile styles
   ========================================================================== */
#ep-offcanvas-nav .uk-navbar-toggle {
  min-height: 3rem;
}

#ep-offcanvas-nav .uk-offcanvas .uk-offcanvas-bar .uk-subnav {
  margin-top: 0.3rem;
}

/* ==========================================================================
Block 1 styles
========================================================================== */
@media (min-width: 640px) {
  #ep-block-1 #ep-block-level-1 img {
    height: 400px;
  }
}

@media (max-width: 640px) {
  #ep-block-1 #ep-block-level-1 img {
    height: 300px;
  }
}

@media (max-width: 640px) {
  #ep-block-1 #ep-block-level-1 .uk-card-body {
    padding: 0;
    margin-top: 1rem;
  }
}

#ep-block-1 #ep-block-level-1 .uk-card-body .uk-card-title {
  margin-bottom: 0.5rem;
}

#ep-block-1 #ep-block-level-1 .uk-card-body .uk-card-title a {
  font-size: 2.5rem;
  font-family: "Merriweather", serif;
  font-weight: bold;
  color: #333 !important;
}

@media (max-width: 640px) {
  #ep-block-1 #ep-block-level-1 .uk-card-body .uk-card-title a {
    font-size: 1.75rem;
  }
}

@media (min-width: 640px) and (max-width: 960px) {
  #ep-block-1 #ep-block-level-1 .uk-card-body .uk-card-title a {
    font-size: 2rem;
  }
}

#ep-block-1 #ep-block-level-1 .uk-card-body .uk-card-title a:hover {
  color: #c91f37 !important;
  text-decoration: none;
}

#ep-block-1 #ep-block-level-1 .uk-card-body div {
  font-family: "Merriweather", serif;
}

@media (max-width: 640px) {
  #ep-block-1 #ep-block-level-2 .uk-card-body {
    padding: 0.5rem 0;
  }
}

#ep-block-1 #ep-block-level-2 .uk-card-body .uk-card-title a {
  font-family: "Merriweather", serif;
  font-weight: bold;
  color: #333 !important;
}

@media (max-width: 960px) {
  #ep-block-1 #ep-block-level-2 .uk-card-body .uk-card-title a {
    font-size: 1.25rem;
  }
}

#ep-block-1 #ep-block-level-2 .uk-card-body .uk-card-title a:hover {
  color: #c91f37 !important;
  text-decoration: none;
}

#ep-block-1 #ep-block-level-3 .uk-card {
  margin-bottom: 10px;
}

@media (max-width: 640px) {
  #ep-block-1 #ep-block-level-3 .uk-card .uk-card-body {
    padding: 0;
  }
}

#ep-block-1 #ep-block-level-3 .uk-card .uk-card-body .uk-card-title {
  margin-bottom: 0rem;
}

#ep-block-1 #ep-block-level-3 .uk-card .uk-card-body .uk-card-title a {
  font-size: 1.25rem;
  font-family: "Merriweather", serif;
  font-weight: bold;
  color: #333 !important;
}

#ep-block-1 #ep-block-level-3 .uk-card .uk-card-body .uk-card-title a:hover {
  color: #c91f37 !important;
  text-decoration: none;
}

@media (min-width: 640px) and (max-width: 960px) {
  #ep-block-1 #ep-block-level-4::before {
    content: "";
    position: relative;
    display: block;
    margin: 0 0 0.75rem 0;
    border-top: 1px solid #e5e5e5;
  }
}

#ep-block-1 #ep-block-level-4 .uk-list li a {
  font-size: 1rem;
  font-family: "Merriweather", serif;
  font-weight: bold;
  color: #333 !important;
}

#ep-block-1 #ep-block-level-4 .uk-list li a:hover {
  color: #c91f37 !important;
  text-decoration: none;
}

/* ==========================================================================
Block 2 styles
========================================================================== */
#ep-section-block-2 {
  background-color: #FFB900;
  padding-bottom: 0;
  margin-bottom: 2.5rem;
}

#ep-section-block-2 #ep-block-2 {
  padding: 15px 0;
}

#ep-section-block-2 #ep-block-2 .ep-block-level-1 .ep-header-block-2 {
  color: #52272D;
  font-size: 1.75rem;
  font-family: "Roboto", sans-serif;
  letter-spacing: 0.25rem;
  font-weight: 700;
  text-transform: uppercase;
  padding: 2rem 0 0 0;
}

#ep-section-block-2 #ep-block-2 .ep-block-level-1 .ep-header-block-2::before {
  content: attr(ep-attr-title);
  color: rgba(232, 17, 35, 0.25);
  font-size: 3.5rem;
  font-family: "Merriweather", serif;
  letter-spacing: 0;
  text-transform: none;
  position: absolute;
  left: calc(30px + 0.5rem);
  top: 0;
}

#ep-section-block-2 #ep-block-2 .ep-block-level-1 .ep-header-block-2 span {
  position: relative;
}

#ep-section-block-2 #ep-block-2 .ep-block-level-1 .ep-header-block-2 span[uk-icon*="icon"] {
  color: rgba(232, 17, 35, 0.25);
  margin-top: -1.5rem;
}

#ep-section-block-2 #ep-block-2 .ep-block-level-1 .uk-list li:nth-child(n+2) {
  border-top-color: rgba(232, 17, 35, 0.25);
}

#ep-section-block-2 #ep-block-2 .ep-block-level-1 .uk-list li a {
  font-size: 1rem;
  font-family: "Merriweather", serif;
  font-weight: bold;
  color: #333 !important;
}

#ep-section-block-2 #ep-block-2 .ep-block-level-1 .uk-list li a:hover {
  color: #c91f37 !important;
  text-decoration: none;
}

#ep-section-block-2 #ep-block-2 .ep-block-level-2::before {
  border-left-color: rgba(232, 17, 35, 0.25);
  border-top-color: rgba(232, 17, 35, 0.25);
}

@media (min-width: 640px) {
  #ep-section-block-2 #ep-block-2 .ep-block-level-2 img {
    height: 300px;
  }
}

@media (min-width: 640px) {
  #ep-section-block-2 #ep-block-2 .ep-block-level-2 .uk-card-body {
    padding-top: 0;
    padding-right: 0;
  }
}

@media (max-width: 640px) {
  #ep-section-block-2 #ep-block-2 .ep-block-level-2 .uk-card-body {
    padding: 0;
    margin-top: 1rem;
  }
}

#ep-section-block-2 #ep-block-2 .ep-block-level-2 .uk-card-body .uk-card-title a {
  font-size: 1.75rem;
  font-family: "Merriweather", serif;
  font-weight: bold;
  color: #333 !important;
}

@media (max-width: 960px) {
  #ep-section-block-2 #ep-block-2 .ep-block-level-2 .uk-card-body .uk-card-title a {
    font-size: 1.25rem;
  }
}

#ep-section-block-2 #ep-block-2 .ep-block-level-2 .uk-card-body .uk-card-title a:hover {
  color: #c91f37 !important;
  text-decoration: none;
}

#ep-section-block-2 #ep-block-2 .ep-block-level-2 .uk-card-body div {
  font-family: "Merriweather", serif;
}

/* ==========================================================================
Block 3 styles
========================================================================== */
#ep-block-3 #ep-block-3-video {
  text-align: center;
}

@media (min-width: 640px) and (max-width: 960px) {
  #ep-block-3 #ep-block-3-video::after {
    content: "";
    position: relative;
    display: block;
    margin: 0.75rem 0 0 0;
    border-top: 1px solid #e5e5e5;
  }
}

#ep-block-3 #ep-block-3-video .ep-post-info {
  font-size: 0.75rem;
  text-transform: uppercase;
  color: #c91f37;
  font-weight: 700;
}

#ep-block-3 #ep-block-3-video .uk-divider-small {
  margin: 0.5rem 0;
}

#ep-block-3 #ep-block-3-video a {
  font-size: 2.5rem;
  font-family: "Merriweather", serif;
  font-weight: bold;
  color: #333 !important;
}

#ep-block-3 #ep-block-3-video a:hover {
  color: #c91f37 !important;
  text-decoration: none;
}

@media (min-width: 640px) and (max-width: 960px) {
  #ep-block-3 #ep-block-3-video a {
    font-size: 2rem;
  }
}

@media (max-width: 640px) {
  #ep-block-3 #ep-block-3-video a {
    font-size: 1.25rem;
  }
}

@media (min-width: 960px) {
  #ep-block-3 #ep-block-3-level-1 .uk-card img {
    height: 200px;
  }
}

@media (min-width: 640px) and (max-width: 960px) {
  #ep-block-3 #ep-block-3-level-1 .uk-card img {
    height: 300px;
  }
}

@media (max-width: 640px) {
  #ep-block-3 #ep-block-3-level-1 .uk-card .uk-card-body {
    padding-top: 0.5rem;
  }
}

#ep-block-3 #ep-block-3-level-1 .uk-card .uk-card-body .uk-card-title a {
  font-size: 1.75rem;
  font-family: "Merriweather", serif;
  font-weight: bold;
  color: #333 !important;
}

#ep-block-3 #ep-block-3-level-1 .uk-card .uk-card-body .uk-card-title a:hover {
  color: #c91f37 !important;
  text-decoration: none;
}

@media (max-width: 960px) {
  #ep-block-3 #ep-block-3-level-1 .uk-card .uk-card-body .uk-card-title a {
    font-size: 1.25rem;
  }
}

#ep-block-3 #ep-block-3-level-1 .uk-card div {
  font-family: "Merriweather", serif;
}

@media (min-width: 640px) {
  #ep-block-3 #ep-block-3-level-2::before {
    content: "";
    position: relative;
    display: block;
    margin: 0 0 0.75rem 0;
    border-top: 1px solid #e5e5e5;
  }
}

@media (min-width: 960px) {
  #ep-block-3 #ep-block-3-level-2:before {
    left: 0;
  }
}

#ep-block-3 #ep-block-3-level-2 .ep-post-item .uk-card .ep-post-title {
  font-size: 1.25rem;
  font-family: "Merriweather", serif;
  font-weight: bold;
  color: #333 !important;
}

#ep-block-3 #ep-block-3-level-2 .ep-post-item .uk-card .ep-post-title:hover {
  color: #c91f37 !important;
  text-decoration: none;
}

@media (max-width: 960px) {
  #ep-block-3 #ep-block-3-level-2 .ep-post-item .uk-card .ep-post-title {
    font-size: 1rem;
  }
}

#ep-block-3 #ep-block-3-level-2 .ep-post-item .uk-card .ep_post_meta {
  margin-top: 0.5rem;
}

@media (min-width: 960px) {
  #ep-block-3 #ep-block-3-level-3::after {
    content: "";
    position: absolute;
    top: 0;
    bottom: 0;
    border-left: 1px solid #e5e5e5;
    right: -15px;
  }
}

@media (min-width: 960px) {
  #ep-block-3 #ep-block-3-level-3::before {
    content: "";
    position: relative;
    display: block;
    margin: 0 0 0.75rem 0;
    border-top: 1px solid #e5e5e5;
  }
}

#ep-block-3 #ep-block-3-level-3 .uk-list li a {
  font-size: 1rem;
  font-family: "Merriweather", serif;
  font-weight: bold;
  color: #333 !important;
}

#ep-block-3 #ep-block-3-level-3 .uk-list li a:hover {
  color: #c91f37 !important;
  text-decoration: none;
}

/* ==========================================================================
Block 4 styles
========================================================================== */
@media (min-width: 640px) and (max-width: 960px) {
  #ep-block-4 #ep-block-column-1::after {
    content: "";
    position: relative;
    display: block;
    margin: 0.75rem 0 0 0;
    border-top: 1px solid #e5e5e5;
  }
}

@media (min-width: 640px) and (max-width: 960px) {
  #ep-block-4 #ep-block-column-1 .ep-block-4-level-1 .uk-card {
    min-height: 300px;
  }
}

@media (min-width: 640px) and (max-width: 960px) {
  #ep-block-4 #ep-block-column-1 .ep-block-4-level-1 .uk-card > div {
    width: calc(50% - 10px);
  }
}

@media (max-width: 640px) {
  #ep-block-4 #ep-block-column-1 .ep-block-4-level-1 .uk-card .uk-card-body {
    padding-top: 0.5rem;
  }
}

#ep-block-4 #ep-block-column-1 .ep-block-4-level-1 .uk-card .uk-card-body .uk-card-title a {
  font-size: 1.75rem;
  font-family: "Merriweather", serif;
  font-weight: bold;
  color: #333 !important;
}

@media (max-width: 960px) {
  #ep-block-4 #ep-block-column-1 .ep-block-4-level-1 .uk-card .uk-card-body .uk-card-title a {
    font-size: 1.25rem;
  }
}

#ep-block-4 #ep-block-column-1 .ep-block-4-level-1 .uk-card .uk-card-body .uk-card-title a:hover {
  color: #c91f37 !important;
  text-decoration: none;
}

@media (min-width: 640px) and (max-width: 960px) {
  #ep-block-4 #ep-block-column-1 .ep-block-4-level-1 .uk-card .uk-card-media-top {
    position: absolute;
    right: 0;
    top: 0;
  }
}

#ep-block-4 #ep-block-column-1 .ep-block-4-level-1 .uk-card .uk-card-media-top img {
  height: 300px;
}

#ep-block-4 #ep-block-column-1 .ep-block-4-level-1 .uk-card div {
  font-family: "Merriweather", serif;
}

@media (max-width: 640px) {
  #ep-block-4 #ep-block-column-2 .ep-block-4-level-2 .uk-card .uk-card-body {
    padding: 0;
    margin-top: 1rem;
  }
}

#ep-block-4 #ep-block-column-2 .ep-block-4-level-2 .uk-card .uk-card-body .uk-card-title {
  margin-bottom: 0.5rem;
}

#ep-block-4 #ep-block-column-2 .ep-block-4-level-2 .uk-card .uk-card-body .uk-card-title a {
  font-size: 1.25rem;
  font-family: "Merriweather", serif;
  font-weight: bold;
  color: #333 !important;
}

@media (max-width: 960px) {
  #ep-block-4 #ep-block-column-2 .ep-block-4-level-2 .uk-card .uk-card-body .uk-card-title a {
    font-size: 1rem;
  }
}

#ep-block-4 #ep-block-column-2 .ep-block-4-level-2 .uk-card .uk-card-body .uk-card-title a:hover {
  color: #c91f37 !important;
  text-decoration: none;
}

#ep-block-4 #ep-block-column-2 .ep-block-4-level-2 .uk-list li a {
  font-size: 1rem;
  font-family: "Merriweather", serif;
  font-weight: bold;
  color: #333 !important;
}

#ep-block-4 #ep-block-column-2 .ep-block-4-level-2 .uk-list li a:hover {
  color: #c91f37 !important;
  text-decoration: none;
}

/* ==========================================================================
Block 5 styles
========================================================================== */
#ep-block-5 div[ep-badge-tile]::before {
  content: attr(ep-badge-tile);
  position: absolute;
  display: block;
  text-transform: uppercase;
  font-weight: 700;
  font-family: "Roboto", sans-serif;
  font-size: 0.75rem;
  color: black;
  background-color: rgba(255, 185, 0, 0.9);
  border-radius: 0 2px 2px 0;
  border-left: 0.5rem solid #F7630C;
  padding: 0.15rem 0.5rem 0.15rem 0.5rem;
  margin-top: 0.75rem;
  top: 0;
}

#ep-block-5 img {
  height: 300px;
}

@media (min-width: 640px) and (max-width: 960px) {
  #ep-block-5 img {
    height: 200px;
  }
}

#ep-block-5 .uk-overlay {
  background: -webkit-gradient(linear, left bottom, left top, from(black), to(rgba(0, 0, 0, 0)));
  background: linear-gradient(0deg, black 0%, rgba(0, 0, 0, 0) 100%);
}

#ep-block-5 .uk-overlay a {
  color: white;
  text-shadow: 0px 0px 0.25rem rgba(0, 0, 0, 0.5);
  font-family: "Merriweather", serif;
  font-weight: bold;
  color: white !important;
}

#ep-block-5 .uk-overlay a:hover {
  color: #F5F5F5 !important;
  text-decoration: none;
}

/* ==========================================================================
Block 6 styles
========================================================================== */
#ep-block-6 .ep-block-6-level-1::before {
  content: "";
  position: absolute;
  display: block;
  bottom: 0;
  height: 100%;
  width: 100%;
  z-index: 0;
  background: #FFB900;
  background: linear-gradient(45deg, #FFB900 0%, #F7630C 100%);
  -webkit-box-shadow: inset 0 0 5rem 0 rgba(0, 0, 0, 0.5);
          box-shadow: inset 0 0 5rem 0 rgba(0, 0, 0, 0.5);
  opacity: 0.33;
}

#ep-block-6 .ep-block-6-level-1 img {
  height: 500px;
}

@media (min-width: 640px) and (max-width: 960px) {
  #ep-block-6 .ep-block-6-level-1 img {
    height: 400px;
  }
}

@media (max-width: 640px) {
  #ep-block-6 .ep-block-6-level-1 img {
    height: 300px;
  }
}

#ep-block-6 .ep-block-6-level-1 .uk-overlay a {
  font-size: 1.75rem;
}

@media (max-width: 640px) {
  #ep-block-6 .ep-block-6-level-1 .uk-overlay a {
    font-size: 1.25rem;
  }
}

@media (min-width: 960px) {
  #ep-block-6 .ep-block-6-level-2 img {
    height: 250px;
  }
}

@media (min-width: 640px) and (max-width: 960px) {
  #ep-block-6 .ep-block-6-level-2 img {
    height: 200px;
  }
}

#ep-block-6 .ep-block-6-level-2 .uk-inline:first-child::before {
  content: "";
  position: absolute;
  display: block;
  bottom: 0;
  height: 100%;
  width: 100%;
  z-index: 0;
  background: #E81123;
  background: linear-gradient(45deg, #E81123 0%, #E3008C 100%);
  -webkit-box-shadow: inset 0 0 5rem 0 rgba(0, 0, 0, 0.5);
          box-shadow: inset 0 0 5rem 0 rgba(0, 0, 0, 0.5);
  opacity: 0.33;
}

#ep-block-6 .ep-block-6-level-2 .uk-inline:nth-child(2)::before {
  content: "";
  position: absolute;
  display: block;
  bottom: 0;
  height: 100%;
  width: 100%;
  z-index: 0;
  background: #9A0089;
  background: linear-gradient(45deg, #9A0089 0%, #0078D7 100%);
  -webkit-box-shadow: inset 0 0 5rem 0 rgba(0, 0, 0, 0.5);
          box-shadow: inset 0 0 5rem 0 rgba(0, 0, 0, 0.5);
  opacity: 0.33;
}

#ep-block-6 .ep-block-6-level-2 .uk-inline:nth-child(3)::before {
  content: "";
  position: absolute;
  display: block;
  bottom: 0;
  height: 100%;
  width: 100%;
  z-index: 0;
  background: #0099BC;
  background: linear-gradient(45deg, #0099BC 0%, #00B294 100%);
  -webkit-box-shadow: inset 0 0 5rem 0 rgba(0, 0, 0, 0.5);
          box-shadow: inset 0 0 5rem 0 rgba(0, 0, 0, 0.5);
  opacity: 0.33;
}

#ep-block-6 .ep-block-6-level-2 .uk-inline:last-child::before {
  content: "";
  position: absolute;
  display: block;
  bottom: 0;
  height: 100%;
  width: 100%;
  z-index: 0;
  background: #00CC6A;
  background: linear-gradient(45deg, #00CC6A 0%, #107C10 100%);
  -webkit-box-shadow: inset 0 0 5rem 0 rgba(0, 0, 0, 0.5);
          box-shadow: inset 0 0 5rem 0 rgba(0, 0, 0, 0.5);
  opacity: 0.33;
}

#ep-block-6 .uk-overlay a {
  color: white;
  text-shadow: 0px 0px 0.25rem rgba(0, 0, 0, 0.5);
  font-family: "Merriweather", serif;
  font-weight: bold;
  color: white !important;
}

#ep-block-6 .uk-overlay a:hover {
  color: #F5F5F5 !important;
  text-decoration: none;
}

@media (max-width: 640px) {
  #ep-block-6 .uk-overlay a {
    font-size: 1rem;
  }
}

/* ==========================================================================
Block 7 styles
========================================================================== */
#ep-block-7 #ep-block-7-level-1 img {
  height: 300px;
}

@media (max-width: 640px) {
  #ep-block-7 #ep-block-7-level-1 .uk-card-body {
    padding: 0;
    margin-top: 1rem;
  }
}

#ep-block-7 #ep-block-7-level-1 .uk-card-body .uk-card-title a {
  font-size: 1.75rem;
  font-family: "Merriweather", serif;
  font-weight: bold;
  color: #333 !important;
}

@media (max-width: 960px) {
  #ep-block-7 #ep-block-7-level-1 .uk-card-body .uk-card-title a {
    font-size: 1.25rem;
  }
}

#ep-block-7 #ep-block-7-level-1 .uk-card-body .uk-card-title a:hover {
  color: #c91f37 !important;
  text-decoration: none;
}

#ep-block-7 #ep-block-7-level-1 .uk-card-body div {
  font-family: "Merriweather", serif;
}

#ep-block-7 #ep-block-7-level-2 .ep-post-item .uk-card .ep-post-title {
  font-size: 1.25rem;
  font-family: "Merriweather", serif;
  font-weight: bold;
  color: #333 !important;
}

@media (max-width: 960px) {
  #ep-block-7 #ep-block-7-level-2 .ep-post-item .uk-card .ep-post-title {
    font-size: 1rem;
  }
}

#ep-block-7 #ep-block-7-level-2 .ep-post-item .uk-card .ep-post-title:hover {
  color: #c91f37 !important;
  text-decoration: none;
}

@media (min-width: 960px) {
  #ep-block-7 #ep-block-7-level-3::before {
    content: "";
    position: relative;
    display: block;
    margin: 0 0 0.75rem 0;
    border-top: 1px solid #e5e5e5;
  }
}

#ep-block-7 #ep-block-7-level-3 .uk-list li a {
  font-size: 1rem;
  font-family: "Merriweather", serif;
  font-weight: bold;
  color: #333 !important;
}

#ep-block-7 #ep-block-7-level-3 .uk-list li a:hover {
  color: #c91f37 !important;
  text-decoration: none;
}

@media (min-width: 960px) {
  #ep-block-7 #ep-block-7-level-ad::before {
    content: "";
    position: relative;
    display: block;
    margin: 0 0 0.75rem 0;
    border-top: 1px solid #e5e5e5;
  }
}

/* ==========================================================================
Block 8 styles
========================================================================== */
@media (min-width: 640px) and (max-width: 960px) {
  #ep-block-8 #ep-block-8-level-1::after {
    content: "";
    position: relative;
    display: block;
    margin: 0.75rem 0 0 0;
    border-top: 1px solid #e5e5e5;
  }
}

@media (min-width: 640px) and (max-width: 960px) {
  #ep-block-8 #ep-block-8-level-1 .uk-card {
    min-height: 300px;
  }
}

@media (min-width: 640px) and (max-width: 960px) {
  #ep-block-8 #ep-block-8-level-1 .uk-card > div {
    width: calc(50% - 10px);
  }
}

@media (max-width: 640px) {
  #ep-block-8 #ep-block-8-level-1 .uk-card .uk-card-body {
    padding-top: 0.5rem;
  }
}

@media (min-width: 640px) and (max-width: 960px) {
  #ep-block-8 #ep-block-8-level-1 .uk-card .uk-card-body {
    padding-top: 0;
  }
}

#ep-block-8 #ep-block-8-level-1 .uk-card .uk-card-body .uk-card-title a {
  font-size: 1.75rem;
  font-family: "Merriweather", serif;
  font-weight: bold;
  color: #333 !important;
}

#ep-block-8 #ep-block-8-level-1 .uk-card .uk-card-body .uk-card-title a:hover {
  color: #c91f37 !important;
  text-decoration: none;
}

@media (max-width: 960px) {
  #ep-block-8 #ep-block-8-level-1 .uk-card .uk-card-body .uk-card-title a {
    font-size: 1.25rem;
  }
}

#ep-block-8 #ep-block-8-level-1 .uk-card div {
  font-family: "Merriweather", serif;
}

@media (min-width: 640px) and (max-width: 960px) {
  #ep-block-8 #ep-block-8-level-1 .uk-card .uk-card-media-top {
    position: absolute;
    right: 0;
    top: 0;
  }
}

@media (min-width: 640px) {
  #ep-block-8 #ep-block-8-level-1 .uk-card .uk-card-media-top img {
    height: 300px;
  }
}

@media (max-width: 640px) {
  #ep-block-8 #ep-block-8-level-2 .ep-post-item .ep_post_meta {
    display: none;
  }
}

#ep-block-8 #ep-block-8-level-2 .ep-post-item .ep_post_meta .uk-subnav {
  padding-left: 0;
}

#ep-block-8 #ep-block-8-level-2 .ep-post-item .ep-post-title {
  font-size: 1.25rem;
  font-family: "Merriweather", serif;
  font-weight: bold;
  color: #333 !important;
}

#ep-block-8 #ep-block-8-level-2 .ep-post-item .ep-post-title:hover {
  color: #c91f37 !important;
  text-decoration: none;
}

@media (max-width: 960px) {
  #ep-block-8 #ep-block-8-level-2 .ep-post-item .ep-post-title {
    font-size: 1rem;
  }
}

#ep-block-8 #ep-block-8-level-3 .uk-list li a {
  font-size: 1rem;
  font-family: "Merriweather", serif;
  font-weight: bold;
  color: #333 !important;
}

#ep-block-8 #ep-block-8-level-3 .uk-list li a:hover {
  color: #c91f37 !important;
  text-decoration: none;
}

/* ==========================================================================
Block 9 styles
========================================================================== */
#ep-block-9 .ep-block-9-level-1 img {
  height: 300px;
}

@media (min-width: 960px) {
  #ep-block-9 .ep-block-9-level-1 img {
    height: 400px;
  }
}

#ep-block-9 .ep-block-9-level-1 .uk-overlay a {
  font-size: 1.75rem;
}

@media (max-width: 960px) {
  #ep-block-9 .ep-block-9-level-1 .uk-overlay a {
    font-size: 1.25rem;
  }
}

@media (max-width: 960px) {
  #ep-block-9 .ep-block-9-level-2:first-child img {
    height: 300px;
  }
}

@media (max-width: 960px) {
  #ep-block-9 .ep-block-9-level-2:first-child .uk-overlay a {
    font-size: 1.25rem;
  }
}

@media (min-width: 960px) {
  #ep-block-9 .ep-block-9-level-2 img {
    height: 300px;
  }
}

@media (min-width: 640px) and (max-width: 960px) {
  #ep-block-9 .ep-block-9-level-2 img {
    height: 200px;
  }
}

#ep-block-9 .ep-block-9-level-2 .uk-overlay a {
  font-size: 1rem;
}

#ep-block-9 .uk-overlay {
  background: -webkit-gradient(linear, left bottom, left top, from(black), to(rgba(0, 0, 0, 0)));
  background: linear-gradient(0deg, black 0%, rgba(0, 0, 0, 0) 100%);
}

#ep-block-9 .uk-overlay a {
  color: white;
  text-shadow: 0px 0px 0.25rem rgba(0, 0, 0, 0.5);
  font-family: "Merriweather", serif;
  font-weight: bold;
  color: white !important;
}

#ep-block-9 .uk-overlay a:hover {
  color: #F5F5F5 !important;
  text-decoration: none;
}

/* ==========================================================================
Block 10 styles
========================================================================== */
#ep-block-10 .ep-block-10-level-1 img {
  height: 500px;
}

@media (max-width: 960px) {
  #ep-block-10 .ep-block-10-level-1 img {
    height: 300px;
  }
}

#ep-block-10 .ep-block-10-level-1 .uk-overlay a {
  font-size: 2rem;
}

@media (max-width: 960px) {
  #ep-block-10 .ep-block-10-level-1 .uk-overlay a {
    font-size: 1.25rem;
  }
}

#ep-block-10 .ep-block-10-level-2 img {
  height: 250px;
}

@media (max-width: 960px) {
  #ep-block-10 .ep-block-10-level-2 img {
    height: 300px;
  }
}

#ep-block-10 .ep-block-10-level-2 .uk-overlay a {
  font-size: 1.25rem;
}

@media (max-width: 960px) {
  #ep-block-10 .ep-block-10-level-2 .uk-overlay a {
    font-size: 1.25rem;
  }
}

@media (min-width: 960px) {
  #ep-block-10 .ep-block-10-level-3 img {
    height: 250px;
  }
}

@media (min-width: 640px) and (max-width: 960px) {
  #ep-block-10 .ep-block-10-level-3 img {
    height: 200px;
  }
}

#ep-block-10 .ep-block-10-level-3 .uk-overlay a {
  font-size: 1rem;
}

@media (max-width: 960px) {
  #ep-block-10 .ep-block-10-level-3 .uk-overlay a {
    font-size: 1rem;
  }
}

#ep-block-10 .uk-overlay {
  background: -webkit-gradient(linear, left bottom, left top, from(black), to(rgba(0, 0, 0, 0)));
  background: linear-gradient(0deg, black 0%, rgba(0, 0, 0, 0) 100%);
}

#ep-block-10 .uk-overlay a {
  color: white;
  text-shadow: 0px 0px 0.25rem rgba(0, 0, 0, 0.5);
  font-family: "Merriweather", serif;
  font-weight: bold;
  color: white !important;
}

#ep-block-10 .uk-overlay a:hover {
  color: #F5F5F5 !important;
  text-decoration: none;
}

/* ==========================================================================
Block 11 styles
========================================================================== */
#ep-block-11 #ep-block-11-level-1 .uk-card img {
  height: 200px;
}

#ep-block-11 #ep-block-11-level-1 .uk-card .uk-card-body .uk-card-title a {
  font-size: 1.25rem;
  font-family: "Merriweather", serif;
  font-weight: bold;
  color: #333 !important;
}

#ep-block-11 #ep-block-11-level-1 .uk-card .uk-card-body .uk-card-title a:hover {
  color: #c91f37 !important;
  text-decoration: none;
}

#ep-block-11 #ep-block-11-level-1 div {
  font-family: "Merriweather", serif;
}

#ep-block-11 .ep-block-11-level-2 .ep-post-item .ep-post-title {
  font-size: 1.25rem;
  font-family: "Merriweather", serif;
  font-weight: bold;
  color: #333 !important;
}

#ep-block-11 .ep-block-11-level-2 .ep-post-item .ep-post-title:hover {
  color: #c91f37 !important;
  text-decoration: none;
}

@media (max-width: 960px) {
  #ep-block-11 .ep-block-11-level-2 .ep-post-item .ep-post-title {
    font-size: 1rem;
  }
}

#ep-block-11 .ep-block-11-level-2 .ep-post-item .ep_post_meta .uk-subnav {
  padding-left: 0;
}

@media (min-width: 640px) and (max-width: 960px) {
  #ep-block-11 #ep-block-11-level-3::before {
    content: "";
    position: relative;
    display: block;
    margin: 0 0 0.75rem 0;
    border-top: 1px solid #e5e5e5;
  }
}

#ep-block-11 #ep-block-11-level-3 .uk-list li a {
  font-size: 1rem;
  font-family: "Merriweather", serif;
  font-weight: bold;
  color: #333 !important;
}

#ep-block-11 #ep-block-11-level-3 .uk-list li a:hover {
  color: #c91f37 !important;
  text-decoration: none;
}

/* ==========================================================================
Block 12 styles
========================================================================== */
#ep-block-12 #ep-block-12-level-1 img {
  height: 200px;
}

#ep-block-12 #ep-block-12-level-1 .uk-card .uk-card-body .uk-card-title a {
  font-size: 1.25rem;
  font-family: "Merriweather", serif;
  font-weight: bold;
  color: #333 !important;
}

#ep-block-12 #ep-block-12-level-1 .uk-card .uk-card-body .uk-card-title a:hover {
  color: #c91f37 !important;
  text-decoration: none;
}

#ep-block-12 #ep-block-12-level-2 .ep-post-item .ep-post-title {
  font-size: 1.25rem;
  font-family: "Merriweather", serif;
  font-weight: bold;
  color: #333 !important;
}

#ep-block-12 #ep-block-12-level-2 .ep-post-item .ep-post-title:hover {
  color: #c91f37 !important;
  text-decoration: none;
}

@media (max-width: 960px) {
  #ep-block-12 #ep-block-12-level-2 .ep-post-item .ep-post-title {
    font-size: 1rem;
  }
}

#ep-block-12 #ep-block-12-level-2 .ep-post-item .uk-subnav {
  padding-left: 0;
}

@media (min-width: 640px) and (max-width: 960px) {
  #ep-block-12 #ep-block-12-level-3::before {
    content: "";
    position: relative;
    display: block;
    margin: 0 0 0.75rem 0;
    border-top: 1px solid #e5e5e5;
  }
}

#ep-block-12 #ep-block-12-level-3 .uk-list li a {
  font-size: 1rem;
  font-family: "Merriweather", serif;
  font-weight: bold;
  color: #333 !important;
}

#ep-block-12 #ep-block-12-level-3 .uk-list li a:hover {
  color: #c91f37 !important;
  text-decoration: none;
}

/* ==========================================================================
Block 10 styles
========================================================================== */
#ep-block-13 .ep-block-13-level-2 .uk-overlay a {
  font-size: 1.75rem;
}

@media (max-width: 640px) {
  #ep-block-13 .ep-block-13-level-2 .uk-overlay a {
    font-size: 1.25rem;
  }
}

#ep-block-13 .ep-block-13-level-3 .uk-overlay a {
  font-size: 1.25rem;
}

@media (max-width: 640px) {
  #ep-block-13 .ep-block-13-level-3 .uk-overlay a {
    font-size: 1.25rem;
  }
}

#ep-block-13 .uk-overlay {
  background: -webkit-gradient(linear, left bottom, left top, from(black), to(rgba(0, 0, 0, 0)));
  background: linear-gradient(0deg, black 0%, rgba(0, 0, 0, 0) 100%);
}

#ep-block-13 .uk-overlay a {
  color: white;
  text-shadow: 0px 0px 0.25rem rgba(0, 0, 0, 0.5);
  font-family: "Merriweather", serif;
  font-weight: bold;
  color: white !important;
}

#ep-block-13 .uk-overlay a:hover {
  color: #F5F5F5 !important;
  text-decoration: none;
}

/* ==========================================================================
Block 14 styles
========================================================================== */
#ep-block-14 #ep-block-14-level-1 img {
  width: 100vw !important;
}

@media (min-width: 960px) {
  #ep-block-14 #ep-block-14-level-1 img {
    height: 500px;
  }
}

@media (min-width: 640px) and (max-width: 960px) {
  #ep-block-14 #ep-block-14-level-1 img {
    height: 400px;
  }
}

@media (max-width: 640px) {
  #ep-block-14 #ep-block-14-level-1 img {
    height: 300px;
  }
}

#ep-block-14 #ep-block-14-level-1 .uk-overlay {
  padding-bottom: 1rem !important;
  background: -webkit-gradient(linear, left bottom, left top, from(black), to(rgba(0, 0, 0, 0)));
  background: linear-gradient(0deg, black 0%, rgba(0, 0, 0, 0) 100%);
}

#ep-block-14 #ep-block-14-level-1 .uk-overlay > a {
  font-family: "Merriweather", serif;
  font-weight: bold;
  color: white !important;
  font-size: 2.5rem;
  color: white;
  text-shadow: 0px 0px 0.25rem rgba(0, 0, 0, 0.5);
}

#ep-block-14 #ep-block-14-level-1 .uk-overlay > a:hover {
  color: #F5F5F5 !important;
  text-decoration: none;
}

@media (max-width: 960px) {
  #ep-block-14 #ep-block-14-level-1 .uk-overlay > a {
    font-size: 1.25rem;
  }
}

@media (max-width: 640px) {
  #ep-block-14 #ep-block-14-level-1 .ep_post_meta {
    display: none;
  }
}

#ep-block-14 #ep-block-14-level-2 .uk-card img {
  height: 200px;
}

#ep-block-14 #ep-block-14-level-2 .uk-card .uk-card-body .uk-card-title a {
  font-size: 1.25rem;
  font-family: "Merriweather", serif;
  font-weight: bold;
  color: #333 !important;
}

#ep-block-14 #ep-block-14-level-2 .uk-card .uk-card-body .uk-card-title a:hover {
  color: #c91f37 !important;
  text-decoration: none;
}

@media (max-width: 960px) {
  #ep-block-14 #ep-block-14-level-2 .uk-card .uk-card-body .uk-card-title a {
    font-size: 1rem;
  }
}

#ep-block-14 #ep-block-14-level-2 .uk-card .ep_post_meta {
  margin-top: 0.5rem;
}

@media (max-width: 960px) {
  #ep-block-14 #ep-block-14-level-3 [uk-grid] {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
  }
}

#ep-block-14 #ep-block-14-level-3 [uk-grid] a {
  font-size: 1rem;
  font-family: "Merriweather", serif;
  font-weight: bold;
  color: #333 !important;
}

#ep-block-14 #ep-block-14-level-3 [uk-grid] a:hover {
  color: #c91f37 !important;
  text-decoration: none;
}

/* ==========================================================================
   Secction 1 styles
   ========================================================================== */
/* ==========================================================================
   Secction 2 styles
   ========================================================================== */
@media (max-width: 640px) {
  #ep_section_style_2 {
    border-bottom: 1px solid #e5e5e5;
  }
}

@media (max-width: 640px) {
  #ep_section_style_2 .uk-card-body {
    padding: 0.5rem 0;
  }
}

#ep_section_style_2 .uk-card-body .uk-card-title a {
  font-size: 1.75rem;
  font-family: "Merriweather", serif;
  font-weight: bold;
  color: #333 !important;
}

#ep_section_style_2 .uk-card-body .uk-card-title a:hover {
  color: #c91f37 !important;
  text-decoration: none;
}

/* ==========================================================================
   Multimedia list styles
   ========================================================================== */
.ep_multimedia_list .ep_header_section_list span {
  color: white;
}

/* ==========================================================================
Estilos posts template 1
========================================================================== */
#ep-style-1-tabs .uk-sticky {
  z-index: 2;
}

#ep-style-1-tabs .uk-sticky .uk-tab .uk-active a {
  border-bottom: 3px solid #c91f37;
}

/* ==========================================================================
   Post multimedia styles
   ========================================================================== */
#ep_multimedia_single {
  position: relative;
}

#ep_multimedia_single:before {
  content: '';
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: -webkit-gradient(linear, left bottom, left top, from(black), to(rgba(0, 0, 0, 0)));
  background: linear-gradient(0deg, black 0%, rgba(0, 0, 0, 0) 100%);
  opacity: .6;
}

#ep_multimedia_single .uk-container {
  position: relative;
}

#ep_multimedia_single .uk-container #ep_multimedia_header .ep_multimedia_title {
  font-size: 2.5rem;
  color: white;
  font-family: "Merriweather", serif;
  font-weight: bold;
  color: white !important;
}

#ep_multimedia_single .uk-container #ep_multimedia_header .ep_multimedia_title:hover {
  color: #F5F5F5 !important;
  text-decoration: none;
}

@media (max-width: 640px) {
  #ep_multimedia_single .uk-container #ep_multimedia_header .ep_multimedia_title {
    font-size: 1.75rem;
  }
}

@media (min-width: 640px) and (max-width: 960px) {
  #ep_multimedia_single .uk-container #ep_multimedia_header .ep_multimedia_title {
    font-size: font-size-xl;
  }
}

@media (max-width: 640px) {
  #ep_multimedia_single .uk-container #ep_multimedia_header {
    height: 300px;
  }
}

@media (min-width: 640px) and (max-width: 960px) {
  #ep_multimedia_single .uk-container #ep_multimedia_header {
    height: 400px;
  }
}

@media (min-width: 960px) {
  #ep_multimedia_single .uk-container #ep_multimedia_header {
    height: 600px;
  }
}

@media (max-width: 640px) {
  #ep_multimedia_meta .uk-clearfix {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
  }
}

/* ==========================================================================
Estilos ad comodin
========================================================================== */
#ep_ad_comodin {
  max-width: 970px;
  background: #0099BC;
  background: linear-gradient(-45deg, #0099BC 0%, #00B294 41%, #00CC6A 100%);
  font-family: "Roboto", sans-serif;
  color: white;
  line-height: 1.25;
}

@media (max-width: 640px) {
  #ep_ad_comodin {
    text-align: center;
  }
}

#ep_ad_comodin .ep_comodin_slogan {
  font-size: 1.75rem;
  font-weight: 300;
}

#ep_ad_comodin .ep_comodin_slogan b {
  font-weight: 700;
}

#ep_ad_comodin .ep_comodin_button a {
  font-size: 1.25rem;
}

#ep_ad_comodin .ep_comodin_button a svg {
  margin-top: -15px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  position: absolute;
}

@media (max-width: 640px) {
  #ep_ad_comodin div:last-child {
    padding-top: 0;
    margin-top: 0;
  }
}

/* ==========================================================================
Special 1 styles
========================================================================== */
#ep-special-1 .ep-special-1-level-1 img {
  height: 300px;
}

@media (min-width: 960px) {
  #ep-special-1 .ep-special-1-level-1 img {
    height: 400px;
  }
}

#ep-special-1 .ep-special-1-level-1 .uk-overlay a {
  font-size: 1.75rem;
}

@media (max-width: 960px) {
  #ep-special-1 .ep-special-1-level-1 .uk-overlay a {
    font-size: 1.25rem;
  }
}

#ep-special-1 .ep-special-1-level-2 img {
  height: 200px;
}

@media (min-width: 960px) {
  #ep-special-1 .ep-special-1-level-2 img {
    height: 400px;
  }
}

#ep-special-1 .ep-special-1-level-2 .uk-overlay a {
  font-size: 1.25rem;
}

@media (max-width: 960px) {
  #ep-special-1 .ep-special-1-level-2 .uk-overlay a {
    font-size: 1rem;
  }
}

#ep-special-1 .ep-special-1-level-3 img {
  height: 200px;
}

#ep-special-1 .ep-special-1-level-3 .uk-overlay a {
  font-size: 1rem;
}

#ep-special-1 .uk-overlay a {
  color: white;
  text-shadow: 0px 0px 0.25rem rgba(0, 0, 0, 0.5);
  font-family: "Merriweather", serif;
  font-weight: bold;
  color: white !important;
}

#ep-special-1 .uk-overlay a:hover {
  color: #F5F5F5 !important;
  text-decoration: none;
}

#ep-special-1.ep_default .uk-overlay {
  background: -webkit-gradient(linear, left bottom, left top, from(black), to(rgba(0, 0, 0, 0)));
  background: linear-gradient(0deg, black 0%, rgba(0, 0, 0, 0) 100%);
}

#ep-special-1.ep_fluent_gradient [ep_item="1"]::before {
  content: "";
  position: absolute;
  display: block;
  bottom: 0;
  height: 100%;
  width: 100%;
  z-index: 0;
  background: #FFB900;
  background: linear-gradient(45deg, #FFB900 0%, #F7630C 100%);
  -webkit-box-shadow: inset 0 0 5rem 0 rgba(0, 0, 0, 0.5);
          box-shadow: inset 0 0 5rem 0 rgba(0, 0, 0, 0.5);
  opacity: 0.5;
}

#ep-special-1.ep_fluent_gradient [ep_item="2"]::before {
  content: "";
  position: absolute;
  display: block;
  bottom: 0;
  height: 100%;
  width: 100%;
  z-index: 0;
  background: #E81123;
  background: linear-gradient(45deg, #E81123 0%, #E3008C 100%);
  -webkit-box-shadow: inset 0 0 5rem 0 rgba(0, 0, 0, 0.5);
          box-shadow: inset 0 0 5rem 0 rgba(0, 0, 0, 0.5);
  opacity: 0.5;
}

#ep-special-1.ep_fluent_gradient [ep_item="3"]::before {
  content: "";
  position: absolute;
  display: block;
  bottom: 0;
  height: 100%;
  width: 100%;
  z-index: 0;
  background: #9A0089;
  background: linear-gradient(45deg, #9A0089 0%, #0078D7 100%);
  -webkit-box-shadow: inset 0 0 5rem 0 rgba(0, 0, 0, 0.5);
          box-shadow: inset 0 0 5rem 0 rgba(0, 0, 0, 0.5);
  opacity: 0.5;
}

#ep-special-1.ep_fluent_gradient [ep_item="4"]::before {
  content: "";
  position: absolute;
  display: block;
  bottom: 0;
  height: 100%;
  width: 100%;
  z-index: 0;
  background: #0099BC;
  background: linear-gradient(45deg, #0099BC 0%, #00B294 100%);
  -webkit-box-shadow: inset 0 0 5rem 0 rgba(0, 0, 0, 0.5);
          box-shadow: inset 0 0 5rem 0 rgba(0, 0, 0, 0.5);
  opacity: 0.5;
}

#ep-special-1.ep_fluent_gradient [ep_item="5"]::before {
  content: "";
  position: absolute;
  display: block;
  bottom: 0;
  height: 100%;
  width: 100%;
  z-index: 0;
  background: #00CC6A;
  background: linear-gradient(45deg, #00CC6A 0%, #107C10 100%);
  -webkit-box-shadow: inset 0 0 5rem 0 rgba(0, 0, 0, 0.5);
          box-shadow: inset 0 0 5rem 0 rgba(0, 0, 0, 0.5);
  opacity: 0.5;
}

#ep-special-1.ep_fluent_gradient [ep_item="6"]::before {
  content: "";
  position: absolute;
  display: block;
  bottom: 0;
  height: 100%;
  width: 100%;
  z-index: 0;
  background: #FFB900;
  background: linear-gradient(45deg, #FFB900 0%, #E81123 100%);
  -webkit-box-shadow: inset 0 0 5rem 0 rgba(0, 0, 0, 0.5);
          box-shadow: inset 0 0 5rem 0 rgba(0, 0, 0, 0.5);
  opacity: 0.5;
}

#ep-special-1.ep_fluent_gradient [ep_item="7"]::before {
  content: "";
  position: absolute;
  display: block;
  bottom: 0;
  height: 100%;
  width: 100%;
  z-index: 0;
  background: #F7630C;
  background: linear-gradient(45deg, #F7630C 0%, #E3008C 100%);
  -webkit-box-shadow: inset 0 0 5rem 0 rgba(0, 0, 0, 0.5);
          box-shadow: inset 0 0 5rem 0 rgba(0, 0, 0, 0.5);
  opacity: 0.5;
}

#ep-special-1.ep_black_middle [ep_item]::before {
  content: "";
  position: absolute;
  display: block;
  bottom: 0;
  height: 100%;
  width: 100%;
  z-index: 0;
  background: black;
  background: linear-gradient(45deg, black 0%, black 100%);
  -webkit-box-shadow: inset 0 0 5rem 0 rgba(0, 0, 0, 0.5);
          box-shadow: inset 0 0 5rem 0 rgba(0, 0, 0, 0.5);
  opacity: 0.75;
}

#ep-special-1.ep_bottom_box [ep_item] .uk-overlay {
  background: rgba(232, 17, 35, 0.8);
  margin: 0 10px;
  padding: 10px;
}

/* ==========================================================================
Special 2 styles
========================================================================== */
#ep-special-2 .ep-special-2-level-1 img {
  height: 300px;
}

@media (min-width: 960px) {
  #ep-special-2 .ep-special-2-level-1 img {
    height: 600px;
  }
}

#ep-special-2 .ep-special-2-level-1 .uk-overlay a {
  font-size: 1.75rem;
}

@media (max-width: 960px) {
  #ep-special-2 .ep-special-2-level-1 .uk-overlay a {
    font-size: 1.25rem;
  }
}

#ep-special-2 .ep-special-2-level-2 img {
  height: 200px;
}

@media (min-width: 960px) {
  #ep-special-2 .ep-special-2-level-2 img {
    height: 400px;
  }
}

@media (min-width: 640px) and (max-width: 960px) {
  #ep-special-2 .ep-special-2-level-2 img {
    height: 300px;
  }
}

#ep-special-2 .ep-special-2-level-2 .uk-overlay a {
  font-size: 1rem;
}

@media (min-width: 960px) {
  #ep-special-2 .ep-special-2-level-2 .uk-overlay a {
    font-size: 1.75rem;
  }
}

@media (min-width: 640px) and (max-width: 960px) {
  #ep-special-2 .ep-special-2-level-2 .uk-overlay a {
    font-size: 1.25rem;
  }
}

@media (min-width: 640px) and (max-width: 960px) {
  #ep-special-2 .ep-special-2-level-2 + .ep-special-2-level-3 img {
    height: 300px;
  }
  #ep-special-2 .ep-special-2-level-2 + .ep-special-2-level-3 .uk-overlay a {
    font-size: 1.25rem;
  }
}

#ep-special-2 .ep-special-2-level-3 img {
  height: 200px;
}

#ep-special-2 .ep-special-2-level-3 .uk-overlay a {
  font-size: 1rem;
}

#ep-special-2 .uk-overlay a {
  color: white;
  text-shadow: 0px 0px 0.25rem rgba(0, 0, 0, 0.5);
  font-family: "Merriweather", serif;
  font-weight: bold;
  color: white !important;
}

#ep-special-2 .uk-overlay a:hover {
  color: #F5F5F5 !important;
  text-decoration: none;
}

#ep-special-2.ep_default .uk-overlay {
  background: -webkit-gradient(linear, left bottom, left top, from(black), to(rgba(0, 0, 0, 0)));
  background: linear-gradient(0deg, black 0%, rgba(0, 0, 0, 0) 100%);
}

#ep-special-2.ep_fluent_gradient [ep_item="1"]::before {
  content: "";
  position: absolute;
  display: block;
  bottom: 0;
  height: 100%;
  width: 100%;
  z-index: 0;
  background: #FFB900;
  background: linear-gradient(45deg, #FFB900 0%, #F7630C 100%);
  -webkit-box-shadow: inset 0 0 5rem 0 rgba(0, 0, 0, 0.5);
          box-shadow: inset 0 0 5rem 0 rgba(0, 0, 0, 0.5);
  opacity: 0.5;
}

#ep-special-2.ep_fluent_gradient [ep_item="2"]::before {
  content: "";
  position: absolute;
  display: block;
  bottom: 0;
  height: 100%;
  width: 100%;
  z-index: 0;
  background: #E81123;
  background: linear-gradient(45deg, #E81123 0%, #E3008C 100%);
  -webkit-box-shadow: inset 0 0 5rem 0 rgba(0, 0, 0, 0.5);
          box-shadow: inset 0 0 5rem 0 rgba(0, 0, 0, 0.5);
  opacity: 0.5;
}

#ep-special-2.ep_fluent_gradient [ep_item="3"]::before {
  content: "";
  position: absolute;
  display: block;
  bottom: 0;
  height: 100%;
  width: 100%;
  z-index: 0;
  background: #9A0089;
  background: linear-gradient(45deg, #9A0089 0%, #0078D7 100%);
  -webkit-box-shadow: inset 0 0 5rem 0 rgba(0, 0, 0, 0.5);
          box-shadow: inset 0 0 5rem 0 rgba(0, 0, 0, 0.5);
  opacity: 0.5;
}

#ep-special-2.ep_fluent_gradient [ep_item="4"]::before {
  content: "";
  position: absolute;
  display: block;
  bottom: 0;
  height: 100%;
  width: 100%;
  z-index: 0;
  background: #0099BC;
  background: linear-gradient(45deg, #0099BC 0%, #00B294 100%);
  -webkit-box-shadow: inset 0 0 5rem 0 rgba(0, 0, 0, 0.5);
          box-shadow: inset 0 0 5rem 0 rgba(0, 0, 0, 0.5);
  opacity: 0.5;
}

#ep-special-2.ep_fluent_gradient [ep_item="5"]::before {
  content: "";
  position: absolute;
  display: block;
  bottom: 0;
  height: 100%;
  width: 100%;
  z-index: 0;
  background: #00CC6A;
  background: linear-gradient(45deg, #00CC6A 0%, #107C10 100%);
  -webkit-box-shadow: inset 0 0 5rem 0 rgba(0, 0, 0, 0.5);
          box-shadow: inset 0 0 5rem 0 rgba(0, 0, 0, 0.5);
  opacity: 0.5;
}

#ep-special-2.ep_fluent_gradient [ep_item="6"]::before {
  content: "";
  position: absolute;
  display: block;
  bottom: 0;
  height: 100%;
  width: 100%;
  z-index: 0;
  background: #FFB900;
  background: linear-gradient(45deg, #FFB900 0%, #E81123 100%);
  -webkit-box-shadow: inset 0 0 5rem 0 rgba(0, 0, 0, 0.5);
          box-shadow: inset 0 0 5rem 0 rgba(0, 0, 0, 0.5);
  opacity: 0.5;
}

#ep-special-2.ep_fluent_gradient [ep_item="7"]::before {
  content: "";
  position: absolute;
  display: block;
  bottom: 0;
  height: 100%;
  width: 100%;
  z-index: 0;
  background: #F7630C;
  background: linear-gradient(45deg, #F7630C 0%, #E3008C 100%);
  -webkit-box-shadow: inset 0 0 5rem 0 rgba(0, 0, 0, 0.5);
          box-shadow: inset 0 0 5rem 0 rgba(0, 0, 0, 0.5);
  opacity: 0.5;
}

#ep-special-2.ep_black_middle [ep_item]::before {
  content: "";
  position: absolute;
  display: block;
  bottom: 0;
  height: 100%;
  width: 100%;
  z-index: 0;
  background: black;
  background: linear-gradient(45deg, black 0%, black 100%);
  -webkit-box-shadow: inset 0 0 5rem 0 rgba(0, 0, 0, 0.5);
          box-shadow: inset 0 0 5rem 0 rgba(0, 0, 0, 0.5);
  opacity: 0.75;
}

#ep-special-2.ep_bottom_box [ep_item] .uk-overlay {
  background: rgba(232, 17, 35, 0.8);
  margin: 0 10px;
  padding: 10px;
}

/* ==========================================================================
Special 3 styles
========================================================================== */
#ep-special-3 .ep-special-3-level-1 img {
  height: 300px;
}

@media (min-width: 960px) {
  #ep-special-3 .ep-special-3-level-1 img {
    height: 400px;
  }
}

#ep-special-3 .ep-special-3-level-1 .uk-overlay a {
  font-size: 1.75rem;
}

@media (max-width: 960px) {
  #ep-special-3 .ep-special-3-level-1 .uk-overlay a {
    font-size: 1.25rem;
  }
}

#ep-special-3 .ep-special-3-level-2 img {
  height: 200px;
}

@media (min-width: 960px) {
  #ep-special-3 .ep-special-3-level-2 img {
    height: 400px;
  }
}

#ep-special-3 .ep-special-3-level-2 .uk-overlay a {
  font-size: 1.25rem;
}

@media (max-width: 960px) {
  #ep-special-3 .ep-special-3-level-2 .uk-overlay a {
    font-size: 1rem;
  }
}

#ep-special-3 .ep-special-3-level-3 img {
  height: 200px;
}

#ep-special-3 .ep-special-3-level-3 .uk-overlay a {
  font-size: 1rem;
}

#ep-special-3 .uk-overlay a {
  color: white;
  text-shadow: 0px 0px 0.25rem rgba(0, 0, 0, 0.5);
  font-family: "Merriweather", serif;
  font-weight: bold;
  color: white !important;
}

#ep-special-3 .uk-overlay a:hover {
  color: #F5F5F5 !important;
  text-decoration: none;
}

#ep-special-3.ep_default .uk-overlay {
  background: -webkit-gradient(linear, left bottom, left top, from(black), to(rgba(0, 0, 0, 0)));
  background: linear-gradient(0deg, black 0%, rgba(0, 0, 0, 0) 100%);
}

#ep-special-3.ep_fluent_gradient [ep_item="1"]::before {
  content: "";
  position: absolute;
  display: block;
  bottom: 0;
  height: 100%;
  width: 100%;
  z-index: 0;
  background: #FFB900;
  background: linear-gradient(45deg, #FFB900 0%, #F7630C 100%);
  -webkit-box-shadow: inset 0 0 5rem 0 rgba(0, 0, 0, 0.5);
          box-shadow: inset 0 0 5rem 0 rgba(0, 0, 0, 0.5);
  opacity: 0.5;
}

#ep-special-3.ep_fluent_gradient [ep_item="2"]::before {
  content: "";
  position: absolute;
  display: block;
  bottom: 0;
  height: 100%;
  width: 100%;
  z-index: 0;
  background: #E81123;
  background: linear-gradient(45deg, #E81123 0%, #E3008C 100%);
  -webkit-box-shadow: inset 0 0 5rem 0 rgba(0, 0, 0, 0.5);
          box-shadow: inset 0 0 5rem 0 rgba(0, 0, 0, 0.5);
  opacity: 0.5;
}

#ep-special-3.ep_fluent_gradient [ep_item="3"]::before {
  content: "";
  position: absolute;
  display: block;
  bottom: 0;
  height: 100%;
  width: 100%;
  z-index: 0;
  background: #9A0089;
  background: linear-gradient(45deg, #9A0089 0%, #0078D7 100%);
  -webkit-box-shadow: inset 0 0 5rem 0 rgba(0, 0, 0, 0.5);
          box-shadow: inset 0 0 5rem 0 rgba(0, 0, 0, 0.5);
  opacity: 0.5;
}

#ep-special-3.ep_fluent_gradient [ep_item="4"]::before {
  content: "";
  position: absolute;
  display: block;
  bottom: 0;
  height: 100%;
  width: 100%;
  z-index: 0;
  background: #0099BC;
  background: linear-gradient(45deg, #0099BC 0%, #00B294 100%);
  -webkit-box-shadow: inset 0 0 5rem 0 rgba(0, 0, 0, 0.5);
          box-shadow: inset 0 0 5rem 0 rgba(0, 0, 0, 0.5);
  opacity: 0.5;
}

#ep-special-3.ep_fluent_gradient [ep_item="5"]::before {
  content: "";
  position: absolute;
  display: block;
  bottom: 0;
  height: 100%;
  width: 100%;
  z-index: 0;
  background: #00CC6A;
  background: linear-gradient(45deg, #00CC6A 0%, #107C10 100%);
  -webkit-box-shadow: inset 0 0 5rem 0 rgba(0, 0, 0, 0.5);
          box-shadow: inset 0 0 5rem 0 rgba(0, 0, 0, 0.5);
  opacity: 0.5;
}

#ep-special-3.ep_fluent_gradient [ep_item="6"]::before {
  content: "";
  position: absolute;
  display: block;
  bottom: 0;
  height: 100%;
  width: 100%;
  z-index: 0;
  background: #FFB900;
  background: linear-gradient(45deg, #FFB900 0%, #E81123 100%);
  -webkit-box-shadow: inset 0 0 5rem 0 rgba(0, 0, 0, 0.5);
          box-shadow: inset 0 0 5rem 0 rgba(0, 0, 0, 0.5);
  opacity: 0.5;
}

#ep-special-3.ep_fluent_gradient [ep_item="7"]::before {
  content: "";
  position: absolute;
  display: block;
  bottom: 0;
  height: 100%;
  width: 100%;
  z-index: 0;
  background: #F7630C;
  background: linear-gradient(45deg, #F7630C 0%, #E3008C 100%);
  -webkit-box-shadow: inset 0 0 5rem 0 rgba(0, 0, 0, 0.5);
          box-shadow: inset 0 0 5rem 0 rgba(0, 0, 0, 0.5);
  opacity: 0.5;
}

#ep-special-3.ep_black_middle [ep_item]::before {
  content: "";
  position: absolute;
  display: block;
  bottom: 0;
  height: 100%;
  width: 100%;
  z-index: 0;
  background: black;
  background: linear-gradient(45deg, black 0%, black 100%);
  -webkit-box-shadow: inset 0 0 5rem 0 rgba(0, 0, 0, 0.5);
          box-shadow: inset 0 0 5rem 0 rgba(0, 0, 0, 0.5);
  opacity: 0.75;
}

#ep-special-3.ep_bottom_box [ep_item] .uk-overlay {
  background: rgba(232, 17, 35, 0.8);
  margin: 0 10px;
  padding: 10px;
}
